import React from 'react'
import { StaticQuery, graphql } from "gatsby"


import './styles';
import BFHeader from './BFHeader';
import Footer from './Footer';
import Page from './Page';
import Meta from './Meta';

const Layout = ({children }) => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
          site {
            siteMetadata {
              title,
              description,
            }
          }
        }
    `}
    render={data => (
      <div>
        <Meta/>
        <BFHeader/>
        <Page>
          {children}
        </Page>
        <Footer />
      </div>
    )}
  />
)

export default Layout
